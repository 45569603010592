/** @jsx jsx */
import { jsx } from 'theme-ui';
import SectionHeader from '../Common/SectionHeader';
import Member from '../Common/Member';

const Directors = () => {
  const members = [
    {
      name: 'Tenae Shipley',
      position: 'Vice President, Bank of Tennessee',
      description: `Tenae Shipley is a Vice President/ Treasury Management Sales Officer at Bank of Tennessee, where she
      has worked since 2017. Tenae’s extensive history in banking also includes service as a Branch Manager
      for FSG Bank from 2003 to 2016 and was the Branch Manager. She is married to Jim, Captain of the
      Major Crimes Unit for the Knox County Sheriff’s Department. They have two daughters, Faith and Grace.`,
      image: '/images/staff/TanaeShipley_pic.jpg',
    },
    {
      name: 'Roger Redding',
      position: 'Retired Executive Director, Holston Conference Foundation',
      description: `Roger Redding  served as the Executive Director of the Holston Conference Foundation for 18 years (2000-2018), increasing assets under management from $25 million to $125 million at the time of his retirement. He has over 40 years of experience working with non-profits, including 10 years with St. Jude Children’s Research Hospital as a Development Officer and 12 years as Vice Chancellor of Development and Alumni Relations with UT Medical Center. He and his wife Rebecca reside in Maryville. They are active members of Cokesbury United Methodist Church.  They have two adult children, Russell Redding in Johnson City and Leslie Rosenbaum (husband Corey) in Knoxville.`,
      image: '/images/staff/Redding-image-150x150.jpeg',
    },
    {
      name: 'Steve Craft',
      position: 'Senior Pastor, Victory Baptist Church',
      description: `Steve graduated from Baptist Bible College in 1984, then moved to Cincinnati, where he
      served as an assistant pastor to two different churches for a total of 14 years. In 1998,
      God called Steve to plant Heritage Baptist Church in Lebanon, Ohio. During his 12 years of
      ministry there, God grew the church’s core group of 17 people to nearly 600 members. It
      was during this time that Steve graduated from Liberty University with two different
      master’s degrees.<br/><br/>In 2010, Steve became the senior pastor of Victory Baptist Church in Maryville,
      Tennessee. He and his wife Brenda have three children and nine grandchildren.`,
      image: '/images/staff/SteveCraft.jpg',
    },
    {
      name: 'Lisa Laugherty',
      position: 'Troubled Asset Specialist, HUD',
      description: `Lisa is a native of the Washington, DC metropolitan area but has called East Tennessee home for 20 years now. She is a wife to Brandon and together they have 3 kids through the NEDC, a 10-year-old and 8-year-old twins.  Lisa and Brandon are both active in their local church and are passionate about raising awareness of embryo donation/adoption. She works full-time as a Troubled Asset Specialist addressing at-risk properties at Housing and Urban Development.`,
      image: '/images/staff/LisaLaugherty.jpg',
    },
    {
      name: 'Donna Harrison',
      position: 'MD/ Director of Research, AAPLOG',
      description: `Dr. Donna Harrison is a physician, board-certified in obstetrics and gynecology. She is currently serving as Director of Research for the American Association of Pro-Life Obstetricians and Gynecologists, the largest non-sectarian pro-life physician organization in the world, with over 6000 members across the United States, and associate members worldwide. Under her leadership as past CEO, AAPLOG doubled membership and launched the annual Matthew Bulfin Educational Conference, amid many other accomplishments.`,
      image: '/images/staff/DonnaHarrison.jpg',
    }

  ];

  return (
    <section>
      <div className="text-content">
        <SectionHeader
          heading="Board of Directors"
          styles={{ pt: [null, null, null, '3rem'], h3: { color: '#666' } }}
        />
        <p sx={{ my: '2rem', fontSize: '1.25rem', color: '#666' }}>
          The National Embryo Donation Center Board of Directors oversees the NEDC. Our board
          members come from a lot of different backgrounds. They also have different interests. But
          what unites them is the desire to help couples{' '}
          <a href="https://www.embryodonation.org/adoption/">
            complete their families through embryo adoption
          </a>
          . To learn more about our board members, read their bios below.
        </p>
      </div>
      <div
        sx={{
          bg: '#61A19E',
          backgroundImage: `url('/images/background2.png')`,
        }}
      >
        <div className="text-content">
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '2rem',
              my: '3rem',
            }}
          >
            {members.map((member, index) => (
              <Member key={index} data={{ ...member, index }} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Directors;
